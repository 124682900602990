import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Categorys = () => {
  const { categorys } = useSelector((state) => state.home);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mdtablet: {
      breakpoint: { max: 991, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 3,
    },
    smmobile: {
      breakpoint: { max: 640, min: 0 },
      items: 2,
    },
    xsmobile: {
      breakpoint: { max: 440, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="w-[90%] mx-auto relative">
      <Carousel
        autoPlay={true}
        infinite={true}
        arrows={true}
        responsive={responsive}
        transitionDuration={500}
      >
        {categorys.map((c, i) => (
          <div className="h-[185px] border block" key={i}>
            {c.subCategory.map((item) => (
              <div className="h-[200px] border block">
                <Link
                  className="w-full h-full relative flex p-0 gap-5"
                  to={`/products?category=${item.sname}`}
                >
                  <img
                    src={c.image}
                    alt="image"
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute bottom-6 w-full mx-auto font-bold left-0 flex justify-center items-center">
                    <span className="py-[2px] px-6 bg-[#3330305d] text-white">
                      {c.name}
                    </span>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Categorys;
